
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import PictureComp from '@/components/picture/PictureComp.vue';
import ProviderIcon from '@/components/picture/ProviderIcon.vue';
import BasicButton from '@/ui-components/BasicButton.vue';
import TitleDetailLink from '@/components/nav/links/TitleDetailLink.vue';

import { ObjectType, Platform } from '@/@types/graphql-types';
import { SnowplowContext } from '@/helpers/tracking/providers';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import {
	GetGlobalSmallSpotlightTitlesDocument,
	GetGlobalSmallSpotlightTitlesQueryVariables,
} from '@/pages/graphql/queries/GetGlobalSmallSpotlightTitles.query';

@Component({
	name: 'GlobalSmallSpotlight',
	components: {
		ProviderIcon,
		BasicButton,
		PictureComp,
		TitleDetailLink,
	},
	apollo: {
		globalNewSpotlightTitles: {
			query: GetGlobalSmallSpotlightTitlesDocument,
			variables(): GetGlobalSmallSpotlightTitlesQueryVariables {
				return this.queryVariables;
			},
			update(data) {
				return data.newTitles.edges;
			},
		},
	},
})
export default class GlobalSmallSpotlight extends Vue {
	globalNewSpotlightTitles = [];

	@Prop() language: string;
	@Prop() country: string;

	get queryVariables() {
		return {
			country: this.country,
			date: '2022-11-29',
			first: 2,
			filter: { objectTypes: ObjectType.Show },
			language: this.language,
			platform: Platform.Web,
		};
	}

	get titlesToShow() {
		return this.globalNewSpotlightTitles.filter((title: any) => {
			return title.node.show;
		});
	}

	getBackdrop(backdrops: { backdropUrl: string }[]) {
		return backdrops[0]?.backdropUrl || '';
	}

	getTitle(title: any) {
		return title.node.show;
	}

	getPackageId(title: any) {
		return this.getTitle(title).watchNowOffer?.package.packageId;
	}

	handleMoreInfoClick(title: any, contexts?: SnowplowContext[]) {
		const titleContext = TrackingHelper.getTitleContextGraphql(
			title.objectId,
			title.objectType,
			title?.content?.seasonNumber
		);
		const additionalContexts: SnowplowContext[] = (contexts || []).concat([titleContext]);

		TrackingHelper.trackEvent(
			'userinteraction',
			{
				action: 'more_info_clicked',
				label: 'new_shows',
			},
			additionalContexts
		);
	}
}
